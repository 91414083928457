export const statuses = {
  created: { name: 'Criado', klass: 'bg-dark' },
  in_progress: { name: 'Em andamento', klass: 'bg-info text-dark' },
  completed: { name: 'Concluído', klass: 'bg-success' },
  closed: { name: 'Fechado', klass: 'bg-danger' },
};

export const reasons = {
  cancelation: 'Cancelamento',
  expiration: 'Expiração',
};
