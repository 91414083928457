import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import Input from 'components/Form/Input';
import Loading from 'components/Loading/Loading';
import OptionForm from 'pages/dates/components/Dates/OptionForm';
import Switch from 'components/Form/Switch';
import TextArea from 'components/Form/TextArea';

import { alert, confirm } from 'lib/notifications';
import { storeActions } from 'store';
import { defaultAdditionalInformation } from './constants';

const DateForm = ({ onSave, onClose, resource }) => {
  const [optionDateTypes, setOptionDateTypes] = useState({});
  const dispatch = useDispatch();

  const newDate = {
    options: [],
    date_types: [],
    hidden: true,
  };

  const date = resource || newDate;

  const { register, handleSubmit } = useForm({
    defaultValues: date,
  });

  const onSubmit = (data) => {
    const params = { ...data };
    params.date_types = Object.keys(optionDateTypes)
      .filter((key) => optionDateTypes[key])
      .map((key) => ({ id: key }));

    params.country_code = 'br';
    onSave(params);
  };

  const dateHasType = (id) => !!date.date_types.find((dt) => dt.id === id);

  const fetch = async (res, params = {}) => {
    const result = await res.all(params);
    const { data: body } = result;

    const types = {};
    body.data.forEach((dateType) => {
      types[dateType.id] = dateHasType(dateType.id);
    });
    setOptionDateTypes(types);

    return body.data;
  };

  const { data: dateTypes, isLoading } = useQuery('date-types', () =>
    fetch(API.DateType),
  );

  if (!dateTypes || isLoading) {
    return <Loading />;
  }

  const defaultTab =
    date.options.length > 0 ? date.options[0].label : 'new-option';

  const onDeleteDate = () => {
    confirm(
      'Atenção',
      'Você tem certeza que deseja excluir este date? Esta operação não poderá ser desfeita.',
      () => {
        API.Date.destroy(date.id)
          .then(() => {
            dispatch(storeActions.form.setFormStateToUnchanged());
            const callback = () => {
              onClose();
            };
            alert({
              text: 'Date excluído com sucesso',
              icon: 'success',
              callback,
            });
          })
          .catch(() => {
            alert({ text: 'Não foi possível excluir o date' });
          });
      },
    );
  };

  const compare = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            {resource && resource.id && (
              <>
                <Form.Label>ID</Form.Label>
                <ClipboardCopy copyText={resource.id} ariaLabel="Date ID" />
              </>
            )}

            <Input
              label="Nome"
              register={register('title', { required: true })}
            />

            <TextArea
              label="Descrição"
              register={register('description', { required: true })}
            />

            <Row>
              <Col>
                <Input
                  label="Link para a documentação"
                  register={register('documentation')}
                />
              </Col>
              <Col>
                <Input
                  label="Meta descrição (SEO)"
                  maxLength={160}
                  register={register('meta_description')}
                />
              </Col>
            </Row>
          </Col>

          <Col>
            <Input
              label="Slug"
              register={register('slug', { required: true })}
            />

            <Row className="mb-0">
              <Col>
                <Input
                  label="Score"
                  type="number"
                  register={register('score', { required: true })}
                />
              </Col>
              <Col>
                <Input
                  label="Imagem"
                  register={register('image_url', { required: true })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3">Status</h5>

                <Switch
                  label="Ativo?"
                  disabled={!resource || resource.options.length === 0}
                  register={register('active')}
                />

                <Switch
                  label="Esconder na busca?"
                  disabled={!resource || resource.options.length === 0}
                  register={register('hidden')}
                />
              </Col>
              <Col>
                <h5 className="mb-3">Tipos do date</h5>

                {dateTypes.map((dateType) => (
                  <Switch
                    key={dateType.id}
                    label={dateType.name}
                    checked={optionDateTypes[dateType.id]}
                    onChange={() => {
                      const types = { ...optionDateTypes };
                      types[dateType.id] = !types[dateType.id];
                      setOptionDateTypes(types);
                    }}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <section className="form-actions">
          <Button variant="danger" type="button" onClick={onDeleteDate}>
            <i className="fa fa-trash"></i>&nbsp; Excluir
          </Button>

          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>

          <Button variant="primary" type="submit">
            <i className="fa fa-save"></i>&nbsp; Salvar date
          </Button>
        </section>
      </Form>

      {date.id && (
        <Form.Group className="mb-3">
          <Tabs defaultActiveKey={defaultTab} className="mb-3">
            {date.options.sort(compare).map((option) => (
              <Tab
                key={option.label}
                eventKey={option.label}
                title={option.title}
              >
                <OptionForm date={date} option={option} onClose={onClose} />
              </Tab>
            ))}

            <Tab eventKey="new-option" title={<i className="fa fa-plus"></i>}>
              <OptionForm
                date={date}
                option={{
                  additional_information: defaultAdditionalInformation,
                }}
                onClose={onClose}
              />
            </Tab>
          </Tabs>
        </Form.Group>
      )}
    </>
  );
};

export default DateForm;
