import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { useQuery } from 'react-query';

import API from 'api';
import { formatDateTimeUsingSlashes, parseToUTCDate } from 'lib/date-utils';
import { statuses } from 'api/resources/order';
import Loading from 'components/Loading/Loading';

const LogsTab = ({ order }) => {
  useEffect(() => {
    refetch();
  }, [order]);

  const fetchOrderLogs = async (orderId) => {
    const response = await API.OrderLog.all({ order_id: orderId });
    const { data: body } = response;
    return body.data;
  };

  const {
    data: logs,
    isLoading,
    refetch,
  } = useQuery(['logs', order.id], () => fetchOrderLogs(order.id));

  if (!order || isLoading) {
    return <Loading />;
  }

  const logIcon = (logType) => {
    let icon = null;
    switch (logType) {
      case 'error':
        icon = (
          <i
            className="fa fa-exclamation-circle"
            style={{ color: '#f13434' }}
          ></i>
        );
        break;
      case 'payment':
        icon = <i className="fa fa-dollar-sign" style={{ color: 'green' }}></i>;
        break;
      default:
        icon = null;
    }

    return icon;
  };

  const extractUrls = (string) => string.match(/\bhttps?:\/\/\S+/gi);

  const extraInformationField = (field) => {
    if (!field.value && !field.text) {
      return null;
    }

    if (field.type === 'datetime') {
      const utcDate = parseToUTCDate(field.value);
      return `- <b>${field.text}</b>: ${formatDateTimeUsingSlashes(utcDate)}`;
    }

    if (field.type === 'status') {
      return `- <b>${field.text}</b>: ${statuses[field.value].name}`;
    }

    return `- <b>${field.text}</b>: ${field.value}`;
  };

  const formattedComment = (log) => {
    // Transform line breaks into <br /> tags
    const comment = log.comment || '';
    let text = comment.replaceAll('\n', '<br />');
    const urls = extractUrls(text);

    // Make the URL's clickable
    if (urls && urls.length > 0) {
      urls.forEach((url) => {
        text = text.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
      });
    }

    let extraInfo = ``;
    if (log.extra_information) {
      extraInfo = log.extra_information
        .map((field) => extraInformationField(field))
        .join('<br />');
    }

    return `
      <div>
        <p>${text}</p>
        ${extraInfo}
      </div>
    `;
  };

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th></th>
          <th>Comentário</th>
          <th>Data</th>
          <th>Usuário</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log) => (
          <tr key={log.id}>
            <td style={{ minWidth: '25px', textAlign: 'center' }}>
              {logIcon(log.type)}
            </td>
            <td>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: formattedComment(log),
                }}
              />
            </td>
            <td>{formatDateTimeUsingSlashes(log.inserted_at)}</td>
            <td>{log.user ? log.user.first_name : ''}</td>
            <td>{log.user ? log.user.email : ''}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default LogsTab;
