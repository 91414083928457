import API from 'api';

import DateTypeForm from 'pages/dates/components/DateTypes/DateTypeForm';
import DateTypes from 'pages/dates/components/DateTypes/DateTypes';

const DateTypesController = {
  basePath: '/dates/tipos-de-dates',
  form: DateTypeForm,
  page: DateTypes,
  resourceAPI: API.DateType,
};

export default DateTypesController;
