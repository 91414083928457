/* eslint-disable react/no-array-index-key */
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Plan from './Plan';

import './styles.scss';

const ChangeOption = ({ order, onClose }) => {
  const orderId = order.id;

  const fetchPossibleOptions = async () => {
    const result = await API.OptionChange.all(orderId);
    return result.data;
  };

  const { data: plans, isLoading } = useQuery(
    ['change-option-preview', orderId],
    () => fetchPossibleOptions(orderId),
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <p className="current-monetary-situation">
        O usuário já pagou {order.billing.formatted.value_paid} e possui&nbsp;
        {plans[0].formatted.available_store_credits} em créditos
      </p>

      <section className="plans-container">
        {plans.length > 0 &&
          plans.map((plan, index) => (
            <Plan
              plan={plan}
              index={index}
              key={index}
              order={order}
              onClose={onClose}
            />
          ))}

        {plans.length === 0 && (
          <span className="no-options-available">
            Não há nenhuma outra opção para este date
          </span>
        )}
      </section>

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </section>
    </>
  );
};

export default ChangeOption;
