import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { NamedRoutes } from 'config/routes/named-routes';

const UserTab = ({ user, buildLabel }) => {
  const address = () =>
    `${user.street_address}, ${user.address_number}, ${user.zip_code} - ${user.city}, ${user.state}`;

  return (
    <Container>
      <Row>
        <Col>
          {buildLabel(
            'Nome',
            `${user.first_name} ${user.last_name}`,
            NamedRoutes.user_path(user.id),
          )}
          {buildLabel('Email', user.email)}
          {buildLabel('Telefone', user.phone_number)}
          {buildLabel('Endereço', address())}
        </Col>
      </Row>
    </Container>
  );
};

export default UserTab;
