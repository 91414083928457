import client from './client';

// This class is an especialization of the Resource class. To know how a
// Resource works, please refer to the documentation on the
// src/api/resource.js file.
//
// The difference between a ScopedResource and a Resource is on the URL of the
// resource:
//
// * URI to show details of a dates: /dates/:id
// * URI to show details of the option of a date: /dates/:date_id/options/:id
//
// Because an option belongs to a date, the url is a bit more complex. More
// than providing the option id, we also need to provide information for the
// date, including the path (`/dates`, on this example) to build the URL.
//
// All the methods present on the Resource class are also available here, with
// the only difference that the you need to provide the URI for the scope when
// initializing the class (Option.new({ scope: 'dates', ... })), and that you
// will have to privide the scope_id as the first argument to all functions: all,
// find, create, update, destroy.
//
export default class ScopedResource {
  // All scopde resources receive an options hash in the constructor. This hash
  // accepts two arguments:
  //
  // * rootKey: the root element from which we will retrieve data from the API's
  //   result. Usually, it is the name of the resource, in lowercase and the
  //   singular form.
  //
  //   Example: the order API may return something like this:
  //
  //   ```
  //   {
  //      data: [{ title: 'first order' }, { title: 'second order' }]
  //   }
  //   ```
  //
  //   In this case, rootKey would be `data`. Check the implementation on the
  //   API if you decide not to use the pattern of using the resource name.
  //
  // * uri: each REST endpoint starts with the resource name, in the plural
  //   form. This could be automated, removing the need to provide this value
  //   when instantiating the class, but there are cases where we need more
  //   elaborate URLs, like when a resource is nested inside another resource.
  //   Then, with this uri argument, we can have the flexibility to build the URL
  //   as we see fit.
  //
  //   Usually, it will be `/<resource-name>`, with the resource name in
  //   lowercase and the plural form. For example, we could pass `/orders` to
  //   the Order resource.
  //
  // * scope: the uri of scope to which the resource belongs to. For example,
  //   if the option belongs to a date and the URL is not flat on the server,
  //   the scope for the Option class will be `dates`. That will build a uri
  //   like `/dates/:date_id/options`
  constructor(options = {}) {
    this.rootKey = options.rootKey;
    this.uri = options.uri;
    this.scope = options.scope;
    this.client = client;
  }

  all(scopeId, params = {}) {
    return this.client.get(this.buildUri(scopeId), { params });
  }

  find(scopeId, id) {
    return this.client.get(`${this.buildUri(scopeId)}/${id}`);
  }

  create(scopeId, data) {
    const body = {};
    body[this.rootKey] = data;

    return this.client.post(this.buildUri(scopeId), body);
  }

  update(scopeId, id, data) {
    const body = {};
    body[this.rootKey] = data;

    return this.client.patch(`${this.buildUri(scopeId)}/${id}`, body);
  }

  destroy(scopeId, id, data = {}) {
    return this.client.delete(`${this.buildUri(scopeId)}/${id}`, { data });
  }

  buildUri(scopeId) {
    const formattedScope =
      this.scope[0] === '/' ? this.scope : `/${this.scope}`;
    const formattedUri = this.uri[0] === '/' ? this.uri : `/${this.uri}`;
    return `${formattedScope}/${scopeId}${formattedUri}`;
  }
}
