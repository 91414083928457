import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useQuery } from 'react-query';

import API from 'api';

import CloseRefundModal from 'pages/refunds/components/CloseRefundModal';
import CompleteRefundModal from 'pages/refunds/components/CompleteRefundModal';
import Loading from 'components/Loading/Loading';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { statuses, reasons } from 'api/resources/refund';

import './styles.scss';

const RefundForm = ({ resource, onClose }) => {
  const [disclaimerHighlight, setDisclaimerHighlight] = useState(false);
  const [refund, setRefund] = useState(resource);
  const [movingToComplete, setMovingToComplete] = useState(false);
  const [movingToClosed, setMovingToClosed] = useState(false);

  const fetchRefundLogs = async () => {
    const result = await API.RefundLog.all(refund.id);
    const { data: body } = result;
    return body.data;
  };

  const reload = (response) => {
    const newRefund = response.data.data;
    if (newRefund) {
      setRefund(newRefund);
      refetch();
    } else {
      API.Refund.find(refund.id).then((r) => {
        setRefund(r.data.data);
        refetch();
      });
    }
  };

  const {
    data: logs,
    isLoading,
    refetch,
  } = useQuery('refund-logs', fetchRefundLogs);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h1>Devolução de valores</h1>

      <ul>
        <li>
          <strong>Pedido: </strong>
          <a
            href={NamedRoutes.order_path(refund.order_number)}
            alt={refund.order_number}
          >
            {refund.order_number}
          </a>
        </li>
        <li>
          <strong>Preço do pedido: </strong>
          {refund.billing.formatted.total_price}
        </li>
        <li>
          <strong>Saldo a devolver: </strong>
          {refund.billing.formatted.value_to_refund.replace('-R', 'R')} <br />
          <ul>
            <li>Store credits: {refund.billing.formatted.store_credits}</li>
            <li>Pagamentos: {refund.billing.formatted.payments}</li>
          </ul>
        </li>
        <li>
          <strong>Status:</strong> {statuses[refund.status].name}
        </li>
        <li>
          <strong>Motivo:</strong> {reasons[refund.reason]}
        </li>
      </ul>

      <hr />

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Email</th>
            <th>Comentário</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td>{log.user_email}</td>
              <td>{log.comment}</td>
              <td>{formatDateTimeUsingSlashes(log.inserted_at)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <hr />

      <br />
      {refund.status === 'created' && (
        <p className={`disclaimer ${disclaimerHighlight ? 'highlight' : ''}`}>
          Ao iniciar o processo, todos os créditos (store credits)
          <b> serão devolvidos automaticamente</b>. O restante pago com
          dinheiro, cartão ou pix continuarão pendentes até que alguém transfira
          o dinheiro para quem pagou pelo date e volte aqui no admin para
          completar o processo.
        </p>
      )}

      {refund.status === 'in_progress' && movingToComplete && (
        <CompleteRefundModal
          refund={refund}
          onClose={() => {
            setMovingToComplete(false);
          }}
          reload={reload}
        />
      )}

      {refund.status !== 'completed' &&
        refund.status !== 'closed' &&
        movingToClosed && (
          <CloseRefundModal
            refund={refund}
            onClose={() => {
              setMovingToClosed(false);
            }}
            reload={reload}
          />
        )}

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Voltar
        </Button>

        {refund.status === 'created' && (
          <Button
            variant="warning"
            type="submit"
            onClick={() => {
              API.Refund.update(refund.id, { status: 'in_progress' }).then(
                (response) => {
                  reload(response);
                },
              );
            }}
            onMouseEnter={() => setDisclaimerHighlight(true)}
            onMouseLeave={() => setDisclaimerHighlight(false)}
          >
            Iniciar processo
          </Button>
        )}

        {refund.status === 'in_progress' && (
          <Button
            variant="warning"
            type="submit"
            onClick={() => {
              setMovingToComplete(true);
            }}
          >
            Concluir processo
          </Button>
        )}

        {refund.status !== 'completed' && (
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              setMovingToClosed(true);
            }}
          >
            Encerrar processo
          </Button>
        )}
      </section>
    </>
  );
};

export default RefundForm;
