import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';

import API from 'api';

import Modal from 'components/Modal/Modal';
import { alert } from 'lib/notifications';

const CompleteRefundModal = ({ refund, onClose, reload }) => {
  const [method, setMethod] = useState('pix');
  const [comment, setComment] = useState('');

  const onConcludeHandler = () => {
    if (comment.length === 0) {
      alert({ text: 'Digite um comentário antes de prosseguir' });
    } else {
      API.Refund.update(refund.id, {
        status: 'complete',
        method,
        comment,
      }).then((response) => {
        reload(response);
      });
    }
  };

  return (
    <Modal
      title={`Concluindo devolução - ${refund.order_number}`}
      onClose={onClose}
    >
      <Form.Label htmlFor={`complete-refund-method-${refund.id}`}>
        Comentário
      </Form.Label>
      <Form.Select
        aria-label="Estado"
        defaultValue="pix"
        onChange={(e) => {
          setMethod(e.currentTarget.value);
        }}
      >
        <option value="pix">Pix</option>
        <option value="stripe">Stripe</option>
        <option value="store_credit">Créditos</option>
        <option value="ted">TED</option>
        <option value="doc">DOC</option>
        <option value="cash">Dinheiro</option>
      </Form.Select>

      <br />
      <Form.Label htmlFor={`complete-refund-comment-${refund.id}`}>
        Comentário
      </Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        id={`complete-refund-comment-${refund.id}`}
        aria-describedby="Concluindo devolução"
        onChange={(e) => {
          setComment(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          setComment(e.currentTarget.value);
        }}
      />

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>

        <Button variant="primary" onClick={onConcludeHandler}>
          Concluir
        </Button>
      </section>
    </Modal>
  );
};

export default CompleteRefundModal;
