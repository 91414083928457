/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
export const getQueryParameter = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  // eslint-disable-next-line prefer-template
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
