import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import AdjustmentsTab from 'pages/orders/components/AdjustmentsTab';
import OverviewTab from 'pages/orders/components/OverviewTab';
import BookingTab from 'pages/orders/components/BookingTab';
import Loading from 'components/Loading/Loading';
import LogsTab from 'pages/orders/components/LogsTab';
import OptionTab from 'pages/orders/components/OptionTab';
import QuestionnaireTab from 'pages/orders/components/QuestionnaireTab';
import TimelineTab from 'pages/orders/components/TimelineTab';
import UserTab from 'pages/orders/components/UserTab';

import { confirmWithCustomHtml, alert } from 'lib/notifications';
import { convertToUTC, formatDateTimeUsingSlashes } from 'lib/date-utils';
import { getPathDomainFromUrl } from 'lib/url';
import { statuses as orderStatuses } from 'api/resources/order';

import {
  htmlForDefaultChangeConfirmation,
  htmlForChangeConfirmationWithScheduledDate,
} from 'pages/orders/helpers/changeStatusModal';

import './style.scss';
import 'pages/orders/balance-due.scss';

const waitingForPaymentOption = {
  text: 'Aguardando pagamento',
  value: 'waiting_for_payment',
};

const didntHappenOption = {
  text: 'Não realizado',
  value: 'didnt_happen',
};

const canceledOption = {
  text: 'Cancelado',
  value: 'canceled',
};

const completedOption = {
  text: 'Concluído',
  value: 'completed',
};

const readyOption = {
  text: 'Pronto',
  value: 'ready',
};

const OrderForm = ({ onSave, onClose, resource }) => {
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [resource]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.keyCode === 27) {
        navigate(getPathDomainFromUrl());
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const fetchOrder = async (resourceId) => {
    const response = await API.Order.find(resourceId);
    const { data: body } = response;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
  } = useQuery(['order', resource.id], () => fetchOrder(resource.id));

  if (!order || isLoading) {
    return <Loading />;
  }

  const onSubmit = (data) => {
    onSave(data);
  };

  const buildLabel = (title, text, url) => {
    const link = (
      <a href={url} alt={text}>
        {text}
      </a>
    );

    const buildText = () => (url ? link : text);

    return (
      <div key={title + text}>
        <Form.Label>
          <strong>{title}: </strong>
          {buildText()}
        </Form.Label>
        <br />
      </div>
    );
  };

  const defaultStatusChangeConfirmation = (status) => {
    const text = `Você está prestes a mudar o status do pedido ${order.number} para <b>${status.text}</b>. Preencha os dados abaixos para prosseguir.`;
    confirmWithCustomHtml({
      title: 'Alteração de status',
      onConfirm: (values) => {
        API.Order.update(order.id, {
          status: status.value,
          comment: values.comment,
        }).then(() => {
          refetch();
        });
      },
      html: htmlForDefaultChangeConfirmation(text),
      preConfirm: () => ({
        comment: document.getElementById('swal2-comment').value,
      }),
    });
  };

  const statusChangeConfirmationWithScheduledDate = (status) => {
    const text = `Você está prestes a mudar o status do pedido ${order.number} para <b>${status.text}</b>.`;

    // Even though we used the statusChangeConfirmationWithScheduledDate as
    // the selected confirmation method, depending on the status change the
    // schedule is not applicable, so we should fallback to the default
    // confirmation without a date input.
    const buildHtml =
      status.value === 'waiting_for_payment'
        ? htmlForChangeConfirmationWithScheduledDate
        : htmlForDefaultChangeConfirmation;

    confirmWithCustomHtml({
      title: 'Alteração de status',
      onConfirm: (values) => {
        if (values.scheduled_for !== '') {
          API.Order.update(order.id, {
            status: status.value,
            comment: values.comment,
            scheduled_for: convertToUTC(values.scheduled_for),
          }).then(() => {
            refetch();
          });
        } else {
          alert({ text: 'Lembre-se de escolher uma data para o date' });
        }
      },
      html: buildHtml(text, order),
      preConfirm: () => ({
        comment: document.getElementById('swal2-comment').value,
        scheduled_for: document.getElementById('swal2-datetime').value,
      }),
    });
  };

  const buildDropdownItems = (statuses, onClick) =>
    statuses.map((status) => (
      <Dropdown.Item
        key={status.text}
        as="button"
        onClick={(e) => {
          e.preventDefault();

          if (onClick !== undefined && typeof onClick === 'function') {
            onClick(status);
          }
        }}
      >
        {status.text}
      </Dropdown.Item>
    ));

  const formatStatus = () => orderStatuses[order.status].name;

  if (!order) {
    return null;
  }

  return (
    <div className="order-form">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <section className="order-header-container">
            <span className="back-button text-body" onClick={onClose}>
              <i className="fa fa-arrow-left"></i>
            </span>
            <h3 className="me-2">
              <strong className="me-2">#</strong>
              {order.number}
            </h3>
            <span className={`badge mx-1 ${orderStatuses[order.status].klass}`}>
              {formatStatus(order.status)}
            </span>
            {order.billing.balance_due === 0 && (
              <span className="badge mx-1 bg-success">Pago</span>
            )}
            {order.billing.balance_due < 0 && (
              <span className="badge mx-1 bg-danger">
                Devolução:{' '}
                {order.billing.formatted.balance_due.replace('-R', 'R')}
              </span>
            )}
            {order.billing.balance_due > 0 && (
              <span className="badge mx-1 bg-warning">
                Não pago: {order.billing.formatted.balance_due}
              </span>
            )}
          </section>
          <section className="mb-3 d-grid">
            <span>
              Criado em {formatDateTimeUsingSlashes(order.inserted_at)}
            </span>
            {order.scheduled_for && (
              <span>
                Agendado para{' '}
                {formatDateTimeUsingSlashes(convertToUTC(order.scheduled_for))}
              </span>
            )}
          </section>

          <Tabs defaultActiveKey="order-overview" className="mb-3">
            <Tab eventKey="order-overview" title="Resumo">
              <OverviewTab
                register={register}
                order={order}
                user={order.user}
                buildLabel={buildLabel}
              />
            </Tab>
            <Tab eventKey="order-booking" title="Agendamento">
              <BookingTab
                register={register}
                order={order}
                buildLabel={buildLabel}
              />
            </Tab>

            {Object.keys(order.questionnaire || {}).length > 0 && (
              <Tab eventKey="order-questionnaire" title="Questionário">
                <QuestionnaireTab order={order} buildLabel={buildLabel} />
              </Tab>
            )}

            <Tab eventKey="order-option" title="Opção">
              <OptionTab
                order={order}
                option={order.option}
                buildLabel={buildLabel}
                onClose={onClose}
              />
            </Tab>
            {order.user && (
              <Tab eventKey="order-user" title="Usuário">
                <UserTab
                  register={register}
                  user={order.user}
                  buildLabel={buildLabel}
                />
              </Tab>
            )}

            <Tab eventKey="order-adjustments" title="Movimentações financeiras">
              <AdjustmentsTab
                register={register}
                order={order}
                reload={refetch}
              />
            </Tab>

            <Tab eventKey="logs" title="Logs">
              <LogsTab order={order} />
            </Tab>

            <Tab eventKey="timeline" title="Linha do tempo">
              <TimelineTab order={order} />
            </Tab>
          </Tabs>

          <hr />

          <section className="form-actions">
            <Button variant="secondary" onClick={onClose}>
              Voltar
            </Button>

            {(order.status === 'placed' ||
              order.status === 'waiting_for_changes') && (
              <DropdownButton
                className="display-contents"
                title="Mudar status para..."
                variant="warning"
              >
                {buildDropdownItems(
                  [
                    waitingForPaymentOption,
                    didntHappenOption,
                    canceledOption,
                    completedOption,
                  ],
                  statusChangeConfirmationWithScheduledDate,
                )}
              </DropdownButton>
            )}

            {order.status === 'ready' && (
              <DropdownButton
                className="display-contents"
                title="Mudar status para..."
                variant="warning"
              >
                {buildDropdownItems(
                  [didntHappenOption, canceledOption, completedOption],
                  defaultStatusChangeConfirmation,
                )}
              </DropdownButton>
            )}

            {order.status === 'waiting_for_payment' && (
              <DropdownButton
                className="display-contents"
                title="Mudar status para..."
                variant="warning"
              >
                {buildDropdownItems(
                  [canceledOption, readyOption],
                  defaultStatusChangeConfirmation,
                )}
              </DropdownButton>
            )}
          </section>
        </Form.Group>
      </Form>
    </div>
  );
};

export default OrderForm;
