import React, { useState } from 'react';
import Kalend, { CalendarView } from 'kalend';

import API from 'api';

import Modal from 'components/Modal/Modal';
import OrderForm from 'pages/orders/components/OrderForm';
import { addHours, convertToTimezone, parseToUTCString } from 'lib/date-utils';
import { randomColor } from 'lib/colors';

import 'kalend/dist/styles/index.css';

const timezone = 'America/Sao_Paulo';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [rangedata, setRangeData] = useState({});

  const prepareOrdersForCalendar = (orders) =>
    orders.map((order) => {
      const scheduledFor = convertToTimezone(
        order.scheduled_for,
        timezone,
      ).toDate();

      return {
        id: order.id,
        startAt: scheduledFor.toISOString(),
        endAt: addHours(scheduledFor, 2).toISOString(),
        timezoneStartAt: timezone,
        summary: `${order.number} - ${order.date.title}`,
        // TOOD: the colors should mean something. Maybe a color for each
        // primary responsible?
        color: randomColor(),
        calendarID: order.number,
        order,
      };
    });

  const onEventClick = (data) => {
    setCurrentOrder(data.order);
    setEditStatus(true);
  };

  const onPageChange = async (data) => {
    setRangeData(data);
    const { rangeFrom, rangeTo } = data;
    const filters = {
      start_date: parseToUTCString(rangeFrom),
      end_date: parseToUTCString(rangeTo),
      status: 'ready',
    };

    const response = await API.Order.all(filters);

    const { data: body } = response;

    if (body.data !== null) {
      const orders = body.data;
      const calendarEvents = prepareOrdersForCalendar(orders);
      setEvents(calendarEvents);
    }
  };

  const closeModal = () => {
    setEditStatus(false);
    setCurrentOrder(null);
    onPageChange(rangedata);
  };

  const onUpdateHandler = (resource) => {
    API.Order.update(resource.id, resource).then(() => {
      closeModal();
    });
  };

  return (
    <div style={{ height: '500px' }}>
      <h1>Calendário</h1>
      <Kalend
        onEventClick={onEventClick}
        events={events}
        initialDate={new Date().toISOString()}
        hourHeight={60}
        initialView={CalendarView.WEEK}
        disabledViews={[CalendarView.DAY]}
        onPageChange={onPageChange}
        timeFormat="24"
        weekDayStart="Monday"
        calendarIDsHidden={['work']}
        language="en"
      />

      {editStatus && currentOrder && (
        <Modal
          title={`Edição - Pedido ${currentOrder.number}`}
          onClose={closeModal}
          fullscreen
        >
          <OrderForm
            onSave={onUpdateHandler}
            onClose={closeModal}
            resource={currentOrder}
          />
        </Modal>
      )}
    </div>
  );
};

export default Calendar;
