/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import API from 'api';

import AdditionalInformation from 'pages/dates/components/Dates/AdditionalInformation';
import Input from 'components/Form/Input';
import Loading from 'components/Loading/Loading';
import Location from 'pages/dates/components/Dates/Location';
import Responsible from 'pages/dates/components/Dates/Responsible';
import Switch from 'components/Form/Switch';
import TextArea from 'components/Form/TextArea';
import TimeOfDay from 'pages/dates/components/Dates/TimeOfDay';
import TodoList from 'components/TodoList/TodoList';

import { alert, confirm } from 'lib/notifications';
import { storeActions } from 'store';
import { keyPrefix } from 'components/TodoList/keyPrefix';

import './styles.scss';

const OptionForm = ({ date, onClose, option = {} }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(option.active);

  const [title, setTitle] = useState(option.title || '');
  const [label, setLabel] = useState(option.label || '');
  const [position, setPosition] = useState(option.position || 0);
  const [metaDescription, setMetaDescription] = useState(
    option.meta_description || '',
  );
  const [price, setPrice] = useState(
    parseFloat((option.billing ? option.billing.price : 0) || 0),
  );
  const [discount, setDiscount] = useState(
    parseFloat(option.billing?.discount || 0),
  );

  const [brainy, setBrainy] = useState(option.brainy || false);
  const [carRequired, setCarRequired] = useState(option.car_required || false);
  const [firstDateFriendly, setFirstDateFriendly] = useState(
    option.first_date_friendly || false,
  );
  const [movement, setMovement] = useState(option.movement || false);
  const [nature, setNature] = useState(option.nature || false);
  const [over18, setOver18] = useState(option.over18 || false);
  const [weatherDependent, setWeatherDependent] = useState(
    option.weather_dependent || false,
  );

  const [mustBring, setMustBring] = useState(option.must_bring || '');
  const [description, setDescription] = useState(option.description || '');
  const [preBookingDescription, setPreBookingDescription] = useState(
    option.pre_booking_description || '',
  );

  const [todoList, setTodoList] = useState(option.todo_list || []);

  const [locations, setLocations] = useState(option.locations || []);
  const [responsibles, setResponsibles] = useState(option.responsibles || []);
  const [timesOfDay, setTimesOfDay] = useState(option.times_of_day || []);
  const [additionalInformation, setAdditionalInformation] = useState(
    option.additional_information || {},
  );

  // error control
  const [locationsErrors, setLocationsErrors] = useState(false);
  const [responsiblesErrors, setResponsiblesErrors] = useState(false);
  const [timesOfDayErrors, setTimesOfDayErrors] = useState(false);
  const [additionalInformationErrors, setAdditionalInformationErrors] =
    useState(false);

  const fetch = async (resource) => {
    const response = await resource.all();
    const { data: body } = response;
    return body.data;
  };

  const { data: states, isLoading: isLoadingStates } = useQuery('states', () =>
    fetch(API.State),
  );

  const { data: responsiblesList, isLoading: isLoadingResponsibles } = useQuery(
    'responsibles',
    () => fetch(API.Responsible),
  );

  const { data: timesOfDayList, isLoading: isLoadingTimesOfDayList } = useQuery(
    'times-of-day',
    () => fetch(API.TimeOfDay),
  );

  if (
    !states ||
    !responsibles ||
    !timesOfDayList ||
    isLoadingStates ||
    isLoadingResponsibles ||
    isLoadingTimesOfDayList
  ) {
    return <Loading />;
  }

  const createOrUpdateOption = (body) => {
    if (option.id) {
      return API.Option.update(date.id, option.id, body);
    }

    return API.Option.create(date.id, body);
  };

  const checked = (value) => value === 'on' || value === true;

  const getData = () => ({
    label,
    title,
    description,
    position,
    pre_booking_description: preBookingDescription,
    meta_description: metaDescription,
    country_code: 'br',
    todo_list: todoList,
    must_bring: mustBring,
    price: parseFloat(price),
    discount: parseFloat(discount),
    over18: checked(over18),
    first_date_friendly: checked(firstDateFriendly),
    car_required: checked(carRequired),
    weather_dependent: checked(weatherDependent),
    movement: checked(movement),
    nature: checked(nature),
    brainy: checked(brainy),
    locations: locations.filter((l) => !l.deleted),
    responsibles: responsibles.filter((r) => !r.deleted),
    times_of_day: timesOfDay.filter((t) => !t.deleted),
    additional_information: additionalInformation,
    active,
  });

  const cloneOption = (currentOption) => {
    const newOption = {
      ...currentOption,
      id: null,
      label: `Cópia de ${currentOption.label}`,
      title: `Cópia de ${currentOption.title}`,
      active: false,
    };

    API.Option.create(date.id, newOption).then(() => {
      dispatch(storeActions.form.setFormStateToModified());
      alert({
        text: `"${newOption.title} - ${newOption.label}" criado com sucesso.`,
        icon: 'success',
        callback: () => window.location.reload(),
      });
    });
  };

  const saveOption = () => {
    if (validateOption()) {
      const data = getData();

      const currentOperation = option.id ? 'update' : 'create';
      createOrUpdateOption(data)
        .then(() => {
          dispatch(storeActions.form.setFormStateToUnchanged());
          const word = currentOperation === 'update' ? 'atualizado' : 'criado';
          alert({
            text: `"${data.title} - ${data.label}" ${word} com sucesso.`,
            icon: 'success',
          });
        })
        .catch(() => {
          alert({ text: 'Não foi possível salvar a opção' });
        });
    } else {
      alert({ text: 'Verifique os campos obrigatórios' });
    }
  };

  const validateOption = () =>
    title.trim().length > 0 &&
    label.trim().length > 0 &&
    parseFloat(price) > 0 &&
    parseFloat(discount) >= 0 &&
    description.trim().length > 0 &&
    !locationsErrors &&
    !timesOfDayErrors &&
    !responsiblesErrors &&
    !additionalInformationErrors;

  const onDeleteOption = () => {
    confirm(
      'Atenção',
      'Você tem certeza que deseja excluir esta opção? Esta operação não poderá ser desfeita.',
      () => {
        API.Option.destroy(date.id, option.id)
          .then(() => {
            dispatch(storeActions.form.setFormStateToUnchanged());
            const callback = () => {
              onClose();
            };
            alert({
              text: 'Opção excluída com sucesso',
              icon: 'success',
              callback,
            });
          })
          .catch(() => {
            alert({ text: 'Não foi possível excluir a opção' });
          });
      },
    );
  };

  const listHeader = (
    headerTitle,
    associationError,
    onClick,
    hideButton = false,
  ) => (
    <div className="association-header">
      {associationError && (
        <i className="fas fa-xs fa-exclamation-circle warning-icon"></i>
      )}
      {headerTitle}
      {associationError}
      {!hideButton && (
        <Button
          variant="primary"
          type="button"
          className="btn-sm association-add-button"
          onClick={onClick}
        >
          <i className="fa fa-plus"></i>
        </Button>
      )}
    </div>
  );

  const onLocationChange = (location, index) => {
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  const onDeleteLocation = (location, index) => {
    onLocationChange({ ...location, deleted: true }, index);
  };

  const onResponsibleChange = (responsible, index) => {
    const newResponsibles = [...responsibles];
    newResponsibles[index] = responsible;
    setResponsibles(newResponsibles);
  };

  const onDeleteResponsible = (responsible, index) => {
    onResponsibleChange({ ...responsible, deleted: true }, index);
  };

  const onTimeOfDayChange = (tod, index) => {
    const newTimesOfDay = [...timesOfDay];
    newTimesOfDay[index] = tod;
    setTimesOfDay(newTimesOfDay);
  };

  const onDeleteTimeOfDay = (tod, index) => {
    onTimeOfDayChange({ ...tod, deleted: true }, index);
  };

  const onAdditionalInformationChange = (instructions) => {
    const newAdditionalInformation = { ...additionalInformation };
    newAdditionalInformation.instructions = instructions.filter(
      (i) => !i.deleted,
    );
    setAdditionalInformation(newAdditionalInformation);
  };

  const classForText = (value) => (value.trim().length === 0 ? 'required' : '');

  const classForNumber = (value) =>
    parseInt(value, 10) <= 0 ? 'required' : '';

  return (
    <div className="option-container">
      <Row className="mb-0">
        <Col>
          <Input
            label="Título*"
            value={title}
            className={classForText(title)}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />

          <Row>
            <Col>
              <Input
                label="Label*"
                value={label}
                className={classForText(label)}
                onChange={(e) => setLabel(e.currentTarget.value)}
              />
            </Col>
            <Col>
              <Input
                label="Posição"
                value={position}
                onChange={(e) => setPosition(e.currentTarget.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Input
                label="Preço*"
                type="number"
                value={parseFloat(price)}
                className={classForNumber(price)}
                onChange={(e) =>
                  setPrice(parseFloat(e.currentTarget.value || 0))
                }
              />

              <Input
                label="Desconto (%)*"
                type="number"
                value={discount}
                min={0}
                max={100}
                onChange={(e) =>
                  setDiscount(parseFloat(e.currentTarget.value || 0))
                }
              />
            </Col>
            <Col className="mt-4">
              <Switch
                label="Com desafios que façam pensar?"
                checked={brainy}
                onChange={(e) => {
                  setBrainy(checked(e.currentTarget.checked));
                }}
              />

              <Switch
                label="Precisa de carro?"
                checked={carRequired}
                onChange={(e) => {
                  setCarRequired(e.currentTarget.checked);
                }}
              />

              <Switch
                label="Bom para primeiro encontro?"
                checked={firstDateFriendly}
                onChange={(e) => {
                  setFirstDateFriendly(e.currentTarget.checked);
                }}
              />
            </Col>
            <Col className="mt-4">
              <Switch
                label="Movimento"
                checked={movement}
                onChange={(e) => setMovement(e.currentTarget.checked)}
              />

              <Switch
                label="Natureza"
                checked={nature}
                onChange={(e) => setNature(e.currentTarget.checked)}
              />

              <Switch
                label="Maiores de 18 anos"
                checked={over18}
                onChange={(e) => setOver18(e.currentTarget.checked)}
              />

              <Switch
                label="Depende do clima"
                checked={weatherDependent}
                onChange={(e) => setWeatherDependent(e.currentTarget.checked)}
              />

              <Switch
                label="Ativo?"
                checked={active}
                onChange={(e) => setActive(e.currentTarget.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Meta descrição (SEO)"
            maxLength={160}
            value={metaDescription}
            className={classForText(metaDescription)}
            onChange={(e) => setMetaDescription(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col>
          <TextArea
            label="Descrição*"
            value={description}
            className={classForText(description)}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <TextArea
            label="Precisa trazer"
            value={mustBring}
            onChange={(e) => setMustBring(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <TextArea
            label="Descrição no pré-booking"
            value={preBookingDescription}
            onChange={(e) => setPreBookingDescription(e.currentTarget.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
          <h3>Lista de Tarefas</h3>
          <TodoList
            list={todoList}
            attributes={{
              onChange: (newTodoList) => {
                setTodoList(newTodoList);
              },
              disabled: true,
              aggregations: [
                {
                  title: 'Custo Estimado',
                  initialValue: 0,
                  fn: (task, acc) => acc + parseFloat(task.cost),
                  format: (value) =>
                    value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
              ],
              types: [
                {
                  name: 'task',
                  type: 'text',
                  title: 'Tarefa',
                },
                {
                  name: 'cost',
                  type: 'number',
                  title: 'Custo',
                  render: (item, attrIndex) => {
                    const key = `${keyPrefix(item)}-task-item-${attrIndex}`;
                    return (
                      <span className="task-cost" key={key}>
                        [R${' '}
                        {parseFloat(item.cost || 0)
                          .toString()
                          .replace('.', ',')}
                        ]
                      </span>
                    );
                  },
                  value: (item) => item.cost,
                },
                {
                  name: 'comment',
                  type: 'textarea',
                  title: 'Comentário',
                },
              ],
            }}
          />
        </Col>
      </Row>

      <Row className="associations-row">
        <hr />
        <Col className="border-right">
          {listHeader(
            'Localizações',
            locationsErrors,
            () => {
              const newLocations = [...locations];
              newLocations.push({});
              setLocations(newLocations);
            },
            locations.length === 1,
          )}
          <Accordion>
            {locations.map((location, index) => (
              <Accordion.Item
                eventKey={`location-${index}`}
                key={`location-${index}`}
                className={location.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {location.title && location.title.trim().length > 0
                    ? location.title
                    : 'Nova localização'}
                </Accordion.Header>
                <Accordion.Body>
                  <Location
                    location={location}
                    onLocationChange={onLocationChange}
                    states={states}
                    locationIndex={index}
                    setHeaderErrors={setLocationsErrors}
                    onDelete={onDeleteLocation}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
        <Col className="border-right">
          {listHeader('Responsáveis', responsiblesErrors, () => {
            const newResponsibles = [...responsibles];
            newResponsibles.push({});
            setResponsibles(newResponsibles);
          })}

          <Accordion>
            {responsibles.map((responsible, index) => (
              <Accordion.Item
                eventKey={index}
                key={`responsible-${index}`}
                className={responsible.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {responsible.id
                    ? responsible.name
                    : `Responsável #${index + 1}`}
                </Accordion.Header>
                <Accordion.Body>
                  <Responsible
                    responsible={responsible}
                    onResponsibleChange={onResponsibleChange}
                    responsiblesList={responsiblesList}
                    index={index}
                    setHeaderErrors={setResponsiblesErrors}
                    onDelete={onDeleteResponsible}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
        <Col className="border-right">
          {listHeader('Períodos do dia', timesOfDayErrors, () => {
            const newTimesOfDay = [...timesOfDay];
            newTimesOfDay.push({});
            setTimesOfDay(newTimesOfDay);
          })}

          <Accordion>
            {timesOfDay.map((tod, index) => (
              <Accordion.Item
                eventKey={index}
                key={`times-of-day-${index}`}
                className={tod.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {tod.id ? tod.name : `Período #${index + 1}`}
                </Accordion.Header>
                <Accordion.Body>
                  <TimeOfDay
                    timeOfDay={tod}
                    onTimeOfDayChange={onTimeOfDayChange}
                    timesOfDayList={timesOfDayList}
                    index={index}
                    setHeaderErrors={setTimesOfDayErrors}
                    onDelete={onDeleteTimeOfDay}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
      <Row className="associations-row">
        <hr />
        <Col>
          <AdditionalInformation
            additionalInformation={additionalInformation}
            listHeader={listHeader}
            onAdditionalInformationChange={onAdditionalInformationChange}
            setAdditionalInformationErrors={setAdditionalInformationErrors}
          />
        </Col>
      </Row>

      <section className="form-actions">
        {option.id && (
          <Button variant="danger" type="button" onClick={onDeleteOption}>
            <i className="fa fa-trash"></i>&nbsp; Excluir {option.title}
          </Button>
        )}
        <Button variant="primary" type="button" onClick={saveOption}>
          Salvar opção
        </Button>

        <Button
          variant="info"
          type="button"
          onClick={() => {
            confirm(
              'Clone wars',
              'Você está prestes a criar uma nova opção com os mesmos dados desta. Tem certeza que deseja continuar?',
              () => {
                cloneOption(getData());
              },
            );
          }}
        >
          Clonar opção
        </Button>
      </section>
    </div>
  );
};

export default OptionForm;
