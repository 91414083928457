import API from 'api';

import DateForm from 'pages/dates/components/Dates/DateForm';
import Dates from 'pages/dates/components/Dates/Dates';

const DatesController = {
  basePath: '/dates',
  form: DateForm,
  page: Dates,
  resourceAPI: API.Date,
};

export default DatesController;
