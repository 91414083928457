/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';

import API from 'api';

import { buildLogsTable } from 'lib/logs-table';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { OPERATIONS } from 'api/resources/store-credit-log';

const OPENED_STATE = 'opened';
const CLOSED_STATE = 'closed';

const ADJUSTMENT_TYPES = {
  payment: 'Pagamento',
  refund: 'Reembolso',
  tax: 'Taxa',
  coupon: 'Cupom',
};

const AdjustmentRow = ({ adjustment }) => {
  const [state, setState] = useState(CLOSED_STATE);
  const [logs, setLogs] = useState();

  const onShowLogsHandler = (adj) => {
    if (!logs) {
      API.StoreCreditLog.allByReference(adj.reference).then((response) => {
        const { data: body } = response;
        setLogs(body.data);
      });
    }

    setState(OPENED_STATE);
  };

  const onHideLogsHandler = () => {
    setState(CLOSED_STATE);
  };

  const toggleLogs = (adj) =>
    state === OPENED_STATE ? onHideLogsHandler() : onShowLogsHandler(adj);

  const logsContainer = () => (
    <tr>
      <td colSpan={6} style={{ textAlign: 'center' }}>
        {buildLogs()}
      </td>
    </tr>
  );

  const buildLogs = () =>
    buildLogsTable(
      [
        {
          header: '# ID',
          attribute: 'id',
          size: 10,
        },
        {
          header: '# Store Credit ID',
          attribute: 'store_credit_id',
          size: 20,
        },
        {
          header: 'Valor',
          attribute: 'formatted',
          size: 15,
          transform: (value) => value.amount,
        },
        {
          header: 'Operação',
          attribute: 'operation',
          size: 15,
          transform: (value) => {
            if (value === 'debit') {
              return (
                <span className="negative-operation">{OPERATIONS[value]}</span>
              );
            }

            if (value === 'credit' || value === 'refund') {
              return (
                <span className="positive-operation">{OPERATIONS[value]}</span>
              );
            }

            return OPERATIONS[value];
          },
        },
        {
          header: 'Data',
          attribute: 'inserted_at',
          size: 25,
          transform: (value) => formatDateTimeUsingSlashes(value),
        },
      ],
      logs,
    );

  return (
    <React.Fragment key={adjustment.id}>
      <tr key={adjustment.id}>
        <td>{adjustment.id}</td>
        <td>{adjustment.formatted.value}</td>
        <td>{adjustment.method}</td>
        <td>{ADJUSTMENT_TYPES[adjustment.type]}</td>
        <td>{formatDateTimeUsingSlashes(adjustment.paid_at)}</td>
        <td style={{ minWidth: '25px', textAlign: 'center' }}>
          {adjustment.method === 'store_credit' && adjustment.reference && (
            <i
              className="fas fa-clipboard-list cursor-pointer"
              onClick={() => toggleLogs(adjustment)}
              title="Logs"
            ></i>
          )}
        </td>
      </tr>
      {state === OPENED_STATE && logs && logsContainer()}
    </React.Fragment>
  );
};

export default AdjustmentRow;
