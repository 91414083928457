export const crop = (text, maxLength, suffix) => {
  if (maxLength > text.length) {
    return text;
  }

  return `${text.substring(0, maxLength)}${suffix}`;
};

export const isEmpty = (value) => value.trim().length === 0;

export const capitalize = (str, divider) => {
  const strWithSpaces = str.split(divider).join(' ');

  const formattedString =
    strWithSpaces.charAt(0).toUpperCase() + strWithSpaces.slice(1);

  return formattedString;
};
