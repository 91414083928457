import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';

// Uncomment this line (and line 52) if you want to debug React Query
// import { ReactQueryDevtools } from 'react-query/devtools';

import Backdrop from 'components/Backdrop/Backdrop';
import CustomRoutes from 'routes';
import Loading from 'components/Loading/Loading';
import Navigation from 'components/Navigation/Navigation';
import TopBar from 'components/TopBar/TopBar';
import { AuthProvider } from 'contexts/AuthContext';

import 'App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const backdrop = useSelector((state) => state.page.backdrop);
  const loading = useSelector((state) => state.page.loading);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // if true, it will refetch data when the browser's tab gains focus.
        // It does not work well on admin because of nested forms.
        refetchOnWindowFocus: false,
      },
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Navigation />

          <div className="right-panel">
            {backdrop && <Backdrop />}

            <TopBar />

            <div className="workspace">
              <CustomRoutes />
            </div>
          </div>

          {/* Uncomment this line if you want to debug React Query */}
          {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
        </QueryClientProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
