import React from 'react';
import { Route } from 'react-router-dom';

import CrudForm from 'components/Crud/CrudForm';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { buildUri } from 'lib/url';
import { NamedRoutes } from 'config/routes/named-routes';

const validActions = ['index', 'edit', 'new'];
const pluralize = (name) => `${name}s`;

/*
  Creates a set of routes to allow users navigate between a resource's CRUD
  operations.

  Which route will be created depend on the parameters passed to the `resources`
  function, and its behavior will depend on the configuration set on the given
  controller.

  Following the standard practices, controller names should be pluralized and
  placed at `config/routes/controllers`.

  Each controller expects these attributes:

  * basePath

    This is the URL used for the index route. All other routes will be built on
    top of that.

    Example: '/usuarios',

    Given the baseUrl above, the `edit` and `new` routes will be, respectively:

    - '/usuarios/:id/editar'
    - '/usuarios/novo'

  * form:

    React component that will render the UI for the `new` and `edit` routes.

    Example: UserForm

  * page:

    React component that will render the UI for the `index` routes.

    Example: Users

  * resourceAPI:

    Each resource has its own way to access the API, either to fetch data or to
    send data back to the server for changing data purposes. Because of that,
    we need to provide the resources function with the proper API resource,
    which will be used to use functions like `all`, `find`, `create`, `update`,
    and `delete`.

    To know more about API resources, check the 'api/resources' folder.

    example: API.User


  Usage examples:

  resources('resourceName', SomeController, { only: 'index' }; // this will create a route for 'index'
  resources('resourceName', SomeController, { only: ['index', 'edit'] }; // this will create a route for 'index' and 'edit'
  resources('resourceName', SomeController, { except: 'new' }; // this will create a route for rota 'index' and 'edit'
  resources('resourceName', SomeController, { except: ['new', 'edit'] }; // this will create a route for 'index'
  resources('resourceName', SomeController; // this will create a route for 'index', 'new' e 'edit'

  To help you build urls from a resource, once you built a route using this
  function you get a named route helper that can be found at `./named-routes`.
  Only existing routes will have a helper, respecting the parameters used here.
  For more information, check the documentation on the NamedRoutes object.
*/
export const resources = (resource, config, params = {}) => {
  const only = Array.isArray(params.only) ? params.only : [params.only];
  const except = Array.isArray(params.except) ? params.except : [params.except];

  let chosenActions = params.only ? only : validActions;
  chosenActions = chosenActions.filter(
    (action) => !except.includes(action) && validActions.includes(action),
  );

  const indexAction = chosenActions.includes('index');
  const editAction = chosenActions.includes('edit');
  const newAction = chosenActions.includes('new');

  const {
    form: Form,
    page: Page,
    resourceAPI,
    basePath,
    resourceLoader,
  } = config;

  if (indexAction) {
    NamedRoutes[`${pluralize(resource)}_path`] = basePath;
  }

  if (editAction) {
    NamedRoutes[`${resource}_path`] = (id) =>
      buildUri([basePath, id, 'editar']);
  }

  if (newAction) {
    NamedRoutes[`new_${resource}_path`] = buildUri([basePath, 'novo']);
  }

  return (
    <>
      {indexAction && (
        <Route
          exact
          path={basePath}
          element={
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          }
        />
      )}

      {newAction && (
        <Route
          exact
          path={`${basePath}/novo`}
          element={
            <PrivateRoute>
              <CrudForm
                formFor={resource}
                form={Form}
                resourceAPI={resourceAPI}
              />
            </PrivateRoute>
          }
        />
      )}

      {editAction && (
        <Route
          exact
          path={`${basePath}/:id/editar`}
          element={
            <PrivateRoute>
              <CrudForm
                form={Form}
                forFor={resource}
                resourceAPI={resourceAPI}
                resourceLoader={resourceLoader}
              />
            </PrivateRoute>
          }
        />
      )}
    </>
  );
};
