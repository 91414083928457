import React from 'react';
import './styles.scss';

import { avatarUrl } from 'lib/avatar';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { capitalize } from 'lib/string';

export default function IncomeTableLine({ adjustment }) {
  const statuses = {
    pre_booking: { name: 'Pré agendado', klass: 'status-pre-booking' },
    placed: { name: 'Requisitado', klass: 'status-placed' },
    waiting_for_changes: {
      name: 'Aguardando alteração',
      klass: 'status-waiting-for-changes',
    },
    waiting_for_payment: {
      name: 'Aguardando pagamento',
      klass: 'status-waiting-for-payment',
    },
    paid: { name: 'Pago', klass: 'status-paid' },
    ready: { name: 'Pronto', klass: 'status-ready' },
    completed: { name: 'Concluído', klass: 'status-completed' },
    didnt_happen: { name: 'Não rolou', klass: 'status-didnt-happen' },
    canceled: { name: 'Cancelado', klass: 'status-canceled' },
  };

  return (
    <tr>
      <td>
        <a href={`/pedidos/${adjustment.order.number}/editar`}>
          {adjustment.order.number}
        </a>
      </td>
      <td>
        <img src={avatarUrl(adjustment.order.user.avatar)} alt="Avatar" />
        {`${
          adjustment.order.user.first_name
        } ${adjustment.order.user.last_name.charAt(0)}.`}
      </td>
      <td className="lineItems">
        {adjustment.order.date.title} -{' '}
        {adjustment.order.billing.formatted.price}
        {adjustment.order.comment && <small>{adjustment.order.comment}</small>}
      </td>
      <td>
        <span className={statuses[adjustment.order.status].klass}>
          {statuses[adjustment.order.status].name}
        </span>
      </td>
      <td>{formatDateTimeUsingSlashes(adjustment.order.inserted_at)}</td>
      <td>{formatDateTimeUsingSlashes(adjustment.paid_at)}</td>
      <td>
        <strong>{adjustment.formatted.value}</strong>
      </td>
      <td>{capitalize(adjustment.method, '_')}</td>
    </tr>
  );
}
