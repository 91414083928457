export const PAGE_BUTTONS_NAMES = {
  DATES_LIST: 'Dates',
  DATES_TYPES: 'Tipos',
  DATES_RESPONSIBLES: 'Responsáveis',
  DATES_TIMES_OF_DAY: 'Períodos',
  DATES_DATA: 'Dados',
};

const contextMenuForDates = [
  {
    name: PAGE_BUTTONS_NAMES.DATES_LIST,
    iconClass: 'fa fa-list',
    path: '/dates',
  },
  {
    name: PAGE_BUTTONS_NAMES.DATES_TYPES,
    iconClass: 'fa fa-theater-masks',
    path: '/dates/tipos-de-dates',
  },
  {
    name: PAGE_BUTTONS_NAMES.DATES_RESPONSIBLES,
    iconClass: 'fa fa-user-shield',
    path: '/dates/responsaveis',
  },
  {
    name: PAGE_BUTTONS_NAMES.DATES_TIMES_OF_DAY,
    iconClass: 'fa fa-clock',
    path: '/dates/horas-do-dia',
  },
  {
    name: PAGE_BUTTONS_NAMES.DATES_DATA,
    iconClass: 'fa fa-database',
    path: '/dates/dados',
  },
];

export const PAGE_BUTTONS = {
  '/dates': contextMenuForDates,
};
