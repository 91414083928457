const menuItems = [
  {
    name: 'Dashboard',
    icon: 'tachometer-alt',
    path: '/',
  },
  {
    name: 'Pedidos',
    icon: 'list-alt',
    path: '/pedidos',
  },
  {
    name: 'Devoluções',
    icon: 'exchange',
    path: '/devolucoes',
  },
  {
    name: 'Usuários',
    icon: 'user-friends',
    path: '/usuarios',
  },
  {
    name: 'Dates',
    icon: 'glass-cheers',
    path: '/dates',
  },
  {
    name: 'Calendário',
    icon: 'calendar-alt',
    path: '/calendario',
  },
  {
    name: 'Mensagens',
    icon: 'envelope-open-text',
    path: '/mensagens',
  },
  {
    name: 'Leads',
    icon: 'handshake',
    path: '/leads',
  },
];

export default menuItems;
