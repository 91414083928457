import API from 'api';

import OrderForm from 'pages/orders/components/OrderForm';
import Orders from 'pages/orders/orders';

const OrdersController = {
  basePath: '/pedidos',
  form: OrderForm,
  page: Orders,
  resourceAPI: API.Order,
  resourceLoader: (number) => API.Order.findByNumber(number),
};

export default OrdersController;
