import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { resources } from 'config/routes/router';

import Calendar from 'pages/calendar/calendar';
import Dashboard from 'pages/dashboard/dashboard';
import Finances from 'pages/finances/finances';
import Login from 'pages/login/login';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import DatesData from 'pages/dates/components/DatesData/DatesData';

import {
  DatesController,
  DateTypesController,
  LeadsController,
  MessagesController,
  OrdersController,
  RefundsController,
  ResponsiblesController,
  TimesOfDaysController,
  UsersController,
} from './config/routes/controllers';

const CustomRoutes = () => (
  <Routes>
    {/* Public routes */}
    <Route exact path="/login" element={<Login />} />

    {/* Private routes */}
    <Route
      exact
      path="/"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />

    {resources('date_type', DateTypesController)}
    {resources('date', DatesController)}
    {resources('lead', LeadsController, { only: 'index' })}
    {resources('message', MessagesController, { except: 'new' })}
    {resources('order', OrdersController, { except: 'new' })}
    {resources('refund', RefundsController, { except: 'new' })}
    {resources('responsible', ResponsiblesController)}
    {resources('times_of_day', TimesOfDaysController)}
    {resources('user', UsersController, { except: 'new' })}

    <Route
      exact
      path="/calendario"
      element={
        <PrivateRoute>
          <Calendar />
        </PrivateRoute>
      }
    />

    <Route
      exact
      path="/dates/dados"
      element={
        <PrivateRoute>
          <DatesData />
        </PrivateRoute>
      }
    />

    <Route
      exact
      path="/finances"
      element={
        <PrivateRoute>
          <Finances />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default CustomRoutes;
