import API from 'api';

import TimeOfDayForm from 'pages/dates/components/TimesOfDay/TimeOfDayForm';
import TimesOfDay from 'pages/dates/components/TimesOfDay/TimesOfDays';

const TimesOfDaysController = {
  basePath: '/dates/horas-do-dia',
  form: TimeOfDayForm,
  page: TimesOfDay,
  resourceAPI: API.TimeOfDay,
};

export default TimesOfDaysController;
