import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Select from 'components/Form/Select';
import { confirm } from 'lib/notifications';

const TimeOfDay = ({
  timeOfDay,
  onTimeOfDayChange,
  timesOfDayList,
  index,
  setHeaderErrors,
  onDelete,
}) => {
  const [id, setId] = useState(timeOfDay.id || 0);
  const hasErrors = () => id <= 0;

  useEffect(() => {
    const value = buildTimeOfDay();

    if (value) {
      onTimeOfDayChange(value, index);
    }

    setHeaderErrors(hasErrors());
  }, [id]);

  const buildTimeOfDay = () =>
    timesOfDayList.find((tod) => tod.id === parseInt(id, 10));

  return (
    <>
      <Select
        className={id <= 0 ? 'required' : ''}
        label="Período"
        options={[{ text: 'Selecione', value: 0 }].concat(
          timesOfDayList.map((tod) => ({
            text: tod.name,
            value: tod.id,
          })),
        )}
        value={id}
        onChange={(e) => setId(e.target.value)}
      />

      {!hasErrors() && (
        <Button
          variant="danger"
          type="button"
          className="btn-sm w-100"
          onClick={() => {
            confirm(
              'Exclusão',
              'Você tem certeza que deseja excluir? A operação só será efetivada depois que você salvar a opção.',
              () => {
                onDelete(buildTimeOfDay(), index);
              },
            );
          }}
        >
          <i className="fa fa-trash"></i> Excluir
        </Button>
      )}
    </>
  );
};

export default TimeOfDay;
