import Resource from './resource';
import ScopedResource from './scoped-resource';

import Account from './resources/account';
import Order from './resources/order';
import StoreCreditLog from './resources/store-credit-log';
import UserStoreCredit from './resources/user-store-credit';

const API = {
  Account: new Account(),
  Date: new Resource({ rootKey: 'date', uri: '/dates' }),
  DateType: new Resource({ rootKey: 'date_type', uri: '/date-types' }),
  Finances: {
    Adjustment: new Resource({
      rootkey: 'data',
      uri: '/finances/adjustments',
    }),
  },
  Lead: new Resource({ rootKey: 'lead', uri: '/leads' }),
  Message: new Resource({ rootKey: 'data', uri: '/contacts' }),
  OptionChange: new ScopedResource({
    rootKey: 'option-change',
    scope: '/orders',
    uri: '/change-option',
  }),
  Option: new ScopedResource({
    rootKey: 'option',
    scope: '/dates',
    uri: '/options',
  }),
  OrderLog: new Resource({ rootKey: 'order-log', uri: 'orders-logs' }),
  Order: new Order({ rootKey: 'order', uri: '/orders' }),
  OrderAdjustment: new ScopedResource({
    rootKey: 'adjustment',
    scope: '/orders',
    uri: '/adjustments',
  }),
  OrderStoreCredit: new ScopedResource({
    rootKey: 'store-credit',
    scope: '/orders',
    uri: '/store-credits',
  }),
  OrderTimeline: new Order({
    rootKey: 'order_timeline',
    uri: '/order_timeline_events',
  }),
  Responsible: new Resource({ rootKey: 'responsible', uri: '/responsibles' }),
  Refund: new Resource({ rootKey: 'refund', uri: '/refunds' }),
  RefundLog: new ScopedResource({
    rootKey: 'refund-log',
    scope: '/refunds',
    uri: '/logs',
  }),
  State: new Resource({ rootKey: 'state', uri: '/states' }),
  StoreCredit: new ScopedResource({
    rootKey: 'store_credit',
    scope: '/users',
    uri: '/store-credits',
  }),
  StoreCreditLog: new StoreCreditLog({
    rootKey: 'store_credit_log',
    scope: '/store-credits',
    uri: '/logs',
  }),
  TimeOfDay: new Resource({ rootKey: 'time_of_day', uri: '/times-of-day' }),
  User: new Resource({ rootKey: 'user', uri: '/users' }),
  UserStoreCredit: new UserStoreCredit({
    rootKey: 'store_credit',
    scope: '/users',
    uri: '/store-credits-total',
  }),
};

export default API;
