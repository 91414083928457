import React from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { storeActions } from 'store';

const Select = (props) => {
  const dispatch = useDispatch();

  const { className, label, onChange, options, register, value, ...attrs } =
    props;

  const selectClassName = `form-select ${className}`;

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Select
        className={selectClassName}
        {...attrs}
        {...register}
        onChange={(e) => {
          dispatch(storeActions.form.setFormStateToModified());

          if (onChange) {
            onChange(e);
          }
        }}
      >
        {options.map((option, index) => (
          <option
            value={option.value}
            // eslint-disable-next-line react/no-array-index-key
            key={`${option.text}-${option.value}-${index}`}
            selected={option.value === value}
          >
            {option.text}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default Select;
