import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import {
  formatDateTime,
  formatDate,
  formatDateTimeUsingSlashes,
} from 'lib/date-utils';
import TodoList from 'components/TodoList/TodoList';
import { keyPrefix } from 'components/TodoList/keyPrefix';

import './style.scss';

const overviewTab = ({ order, user, buildLabel }) => {
  const { date, option } = order;

  const responsibles = option.responsibles.map((r) => r.name).join(', ');

  const { billing } = order;

  const priceTooltip = (
    <Tooltip id="tooltip">
      O preço total é o valor do date escolhido já com os descontos aplicados. O
      cálculo não leva em consideração valores pagos ou devolvidos.
    </Tooltip>
  );

  return (
    <Container>
      <Row className="gx-5">
        <Col className="col-9">
          <Row className="card flex-row mb-3">
            <Col className="col-3 px-0">
              <img
                className="overview-img"
                src={date.image_url}
                alt={date.title}
              />
            </Col>
            <Col className="py-2 col-7 d-flex flex-column justify-content-between">
              <Row>
                <h4>
                  <strong>{date.title}</strong>
                </h4>
                <h5>{option.title}</h5>
              </Row>
              <Row>
                {buildLabel(
                  'Pedido criado em',
                  formatDateTimeUsingSlashes(order.inserted_at),
                )}
              </Row>
            </Col>
            <Col className="col-2 p-1 border-start d-flex flex-column align-items-center justify-content-center">
              <strong className="pt-4">
                {billing.formatted.price}
                <OverlayTrigger placement="left" overlay={priceTooltip}>
                  <i
                    className="fa fa-question-circle ms-2"
                    aria-hidden="true"
                    title="price info"
                  ></i>
                </OverlayTrigger>
              </strong>
              <p className="text-muted">{billing.discount_percentage} off</p>
            </Col>
          </Row>
          {order.status !== 'pre_booking' && (
            <Row className="card mb-3">
              <div className="card-header">
                Agendamento
                {order.status === 'placed' && (
                  <span className="badge mx-1 bg-warning">Pendente</span>
                )}
              </div>
              <div className="card-body">
                {order.scheduled_for ? (
                  <h5>Agendado para {formatDateTime(order.scheduled_for)}</h5>
                ) : (
                  <>
                    <h5>
                      A partir de {formatDate(order.start_date)}, entre{' '}
                      {order.from_time} e {order.to_time}
                    </h5>
                    <h6>{API.Order.weekDaysString(order)}</h6>
                  </>
                )}

                {order.comment
                  ? buildLabel('Comentário', order.comment)
                  : 'Sem comentários para o pedido'}
              </div>
            </Row>
          )}
          <Row>
            <div className="card px-0 mb-3">
              <Tabs defaultActiveKey="necessary-actions" className="mb-3">
                <Tab eventKey="necessary-actions" title="Ações Necessárias">
                  <div className="card-body">
                    <h5 className="card-title">
                      {buildLabel('Reponsáveis', responsibles)}
                    </h5>
                    <div className="form-check">
                      <TodoList
                        list={option.todo_list || []}
                        attributes={{
                          onChange: (newTodoList) => {
                            API.Order.updateTodoList(order.id, newTodoList);
                          },
                          aggregations: [
                            {
                              title: 'Custo Estimado',
                              initialValue: 0,
                              fn: (task, acc) => acc + parseFloat(task.cost),
                              format: (value) =>
                                value.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }),
                            },
                            {
                              title: 'Custo Total',
                              initialValue: 0,
                              fn: (task, acc) =>
                                task.done ? acc + parseFloat(task.cost) : acc,
                              format: (value) =>
                                value.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }),
                            },
                          ],
                          types: [
                            {
                              name: 'task',
                              type: 'text',
                              title: 'Tarefa',
                            },
                            {
                              name: 'cost',
                              type: 'number',
                              title: 'Custo',
                              render: (item, attrIndex) => {
                                const key = `${keyPrefix(
                                  item,
                                )}-task-item-${attrIndex}`;
                                return (
                                  <span className="task-cost" key={key}>
                                    [R${' '}
                                    {parseFloat(item.cost || 0)
                                      .toString()
                                      .replace('.', ',')}
                                    ]
                                  </span>
                                );
                              },
                              value: (item) => item.cost,
                            },
                            {
                              name: 'comment',
                              type: 'textarea',
                              title: 'Comentário',
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="costs" title="Custos">
                  <div className="card-body">Custos do date</div>
                </Tab>
              </Tabs>
            </div>
          </Row>

          <Row>
            <Col>
              {date.documentation && date.documentation.length > 0 && (
                <Button
                  variant="dark"
                  type="button"
                  className="mt-4"
                  onClick={() => {
                    window.open(date.documentation, '_blank');
                  }}
                >
                  <i className="fa fa-book-reader"></i> Leia a documentação
                </Button>
              )}
            </Col>
            <Col>
              <strong>ID do Pedido:</strong>
              <ClipboardCopy copyText={order.id} ariaLabel="Order ID" />
            </Col>
          </Row>
        </Col>
        <Col className="col-3 card px-0" style={{ height: 'fit-content' }}>
          <div className="card-header">Dados do cliente</div>
          <div className="border-bottom p-3 d-flex flex-row align-items-center">
            <div className="avatar">
              <img
                className="rounded-circle img-fluid"
                src={user.avatar}
                alt="user avatar"
              />
            </div>
            <strong className="ps-3">{order.user_name}</strong>
            <a
              className="ms-auto text-body"
              href={`/usuarios/${user.id}/editar`}
            >
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
          <div className="card-body">
            <div className="d-flex flex-column">
              <strong className="pb-2">Contato</strong>
              <p>
                <i className="fa fa-envelope"></i> {order.user_email}
              </p>
              <p>
                <i className="fa fa-phone"></i> {user.phone_number}
              </p>
            </div>
            <br />
            {buildLabel('Nome da companhia', order.significant_other_name)}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default overviewTab;
