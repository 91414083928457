import API from 'api';
import { formatDate } from 'lib/date-utils';

export const htmlForDefaultChangeConfirmation = (text) =>
  `
  <div style="text-align: left">
    <p>
      <label>${text}</label>
    </p>
    <br />
    <label>Comentário:</label>
    <textarea class="swal2-textarea" style="display: flex; margin: 15px 0; width: 100%;" id="swal2-comment"></textarea>
    <br />
  </div>
  `;

export const htmlForChangeConfirmationWithScheduledDate = (text, order) => {
  const weekDays = API.Order.weekDaysString(order);
  const scheduledFor = order.scheduled_for
    ? `<label>Atualmente agendado para ${formatDate(
        order.scheduled_for,
      )}</label>`
    : '';
  return `
    <div style="text-align: left">
      <p>
        <label>${text}</label>
      </p>
      <p style="font-size: 13px">
        <label><strong>Date</strong>: ${order.date.title}</label><br />
        <label><strong>Opção</strong>: ${order.option.title}</label><br />
        <label><strong>Dias preferidos</strong>: ${weekDays}</label> <br />
        <label>
          <strong>Datas</strong>: a partir de ${formatDate(
            order.start_date,
          )}, das
          ${order.from_time} às ${order.to_time}
        </label>
        ${scheduledFor}
      </p>
      <label>Comentário:</label>
      <textarea class="swal2-textarea" style="display: flex; margin: 15px 0; width: 100%; height: 50px;" id="swal2-comment"></textarea>
      <label>Dia e horário sugerido:</label>
      <input id="swal2-datetime" type="datetime-local" class="swal2-input" style="margin-left: 0">
    </div>
  `;
};
