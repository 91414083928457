/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import Loading from 'components/Loading/Loading';
import { alert } from 'lib/notifications';
import { storeActions } from 'store';
import { FORM_STATES } from 'components/Form/formStates';

const CrudForm = ({ formFor, form, resourceAPI, resourceLoader }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();

  const formState = useSelector((state) => state.form.formState);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();

      const { formState: currentFormState } = store.getState().form;

      if (currentFormState !== FORM_STATES.unchanged) {
        event.returnValue = '';
        return '';
      }
    });
  }, [formState]);

  const fetchResource = async () => {
    let result = null;

    if (resourceLoader && typeof resourceLoader === 'function') {
      result = await resourceLoader(id);
    } else {
      result = await resourceAPI.find(id);
    }
    const { data: body } = result;
    return body.data;
  };

  const onSaveHandler = (item) => {
    if (item.id) {
      return onUpdateHandler(item);
    }

    return onCreateHandler(item);
  };

  const onCreateHandler = (item) => {
    resourceAPI.create(item).then(() => {
      dispatch(storeActions.form.setFormStateToUnchanged());
      alert({ text: 'Criado com sucesso!', icon: 'success' }, () => {
        refetch();
      });
    });
  };

  const onUpdateHandler = (item) => {
    resourceAPI.update(item.id, item).then(() => {
      dispatch(storeActions.form.setFormStateToUnchanged());
      alert({ text: 'Salvo com sucesso!', icon: 'success' }, () => {
        refetch();
      });
    });
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    [`crud-page-form-${formFor}-${id}`],
    fetchResource,
    {
      enabled: id !== null && id !== undefined,
    },
  );

  if (isLoading || isRefetching) {
    return <Loading />;
  }

  const ResourceForm = form;

  return (
    <ResourceForm
      onSave={onSaveHandler}
      onClose={() => {
        dispatch(storeActions.form.setFormStateToUnchanged());
        navigate(-1);
      }}
      resource={data}
    />
  );
};

export default CrudForm;
