import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Input from 'components/Form/Input';
import LoadingIcon from 'components/Loading/LoadingIcon';
import Select from 'components/Form/Select';

import { confirm } from 'lib/notifications';
import { isEmpty } from 'lib/string';

const Location = ({
  location,
  states,
  locationIndex,
  onLocationChange,
  setHeaderErrors,
  onDelete,
}) => {
  const [loadingCep, setLoadingCep] = useState(false);

  const [title, setTitle] = useState(location.title || '');
  const [address, setAddress] = useState(location.address || '');
  const [complement, setComplement] = useState(location.complement || '');
  const [reference, setReference] = useState(location.reference || '');
  const [number, setNumber] = useState(location.number || '');
  const [phoneNumber, setPhoneNumber] = useState(location.phone_number || '');
  const [zipCode, setZipCode] = useState(location.zip_code || '');
  const [city, setCity] = useState(location.city || 'São Paulo');
  const [country, setCountry] = useState(location.country || 'Brasil');
  const [state, setState] = useState(location.state || 'SP');

  const hasErrors = () =>
    isEmpty(title) ||
    isEmpty(address) ||
    (isEmpty(number) && isEmpty(complement)) ||
    isEmpty(city) ||
    isEmpty(country);

  useEffect(() => {
    onLocationChange(buildLocation(), locationIndex);
    setHeaderErrors(hasErrors());
  }, [address, complement, reference, number, zipCode, city, country, state]);

  /* "id": 1,
    "title": "MASP",
    "address": "Av. Dom Pedro II x",
    "city": "Santo André",
    "complement": "",
    "country": "Brasil",
    "number": "",
    "phone_number": "",
    "reference": "",
    "state": "SP",
    "zip_code": "04094-050" */
  const buildLocation = () => ({
    id: location.id,
    title,
    address,
    complement,
    reference,
    number,
    phone_number: phoneNumber,
    zip_code: zipCode,
    city,
    country,
    state,
  });

  const searchAddress = async (code) => {
    setLoadingCep(true);
    try {
      const response = await fetch(`https://viacep.com.br/ws/${code}/json/`);
      const data = await response.json();

      setAddress(data.logradouro);
      setCity(data.localidade);
      setState(data.uf);

      return true;
    } catch (error) {
      return false;
    } finally {
      setLoadingCep(false);
    }
  };

  return (
    <>
      <Input
        label="Título*"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className={isEmpty(title) ? 'required' : ''}
      />

      <div className="zip-code-container">
        <Input
          label="CEP"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          onBlur={(e) => {
            if (e.target.value.trim().length > 0) {
              searchAddress(e.target.value);
            }
          }}
        />

        {loadingCep && <LoadingIcon className="zip-code-loading" />}
      </div>

      <Input
        label="Endereço*"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        className={isEmpty(address) ? 'required' : ''}
      />

      <Input
        label="Número"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        className={isEmpty(number) && isEmpty(complement) ? 'required' : ''}
      />

      <Input
        label="Complemento"
        value={complement}
        onChange={(e) => setComplement(e.target.value)}
        className={isEmpty(number) && isEmpty(complement) ? 'required' : ''}
      />

      <Input
        label="Referência"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
      />

      <Input
        label="Cidade*"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        className={isEmpty(city) ? 'required' : ''}
      />

      <Input
        label="País*"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        className={isEmpty(country) ? 'required' : ''}
      />

      <Select
        label="Estado"
        options={states.map((st) => ({ text: st.name, value: st.code }))}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />

      <Input
        label="Telefone"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />

      {!hasErrors() && (
        <Button
          variant="danger"
          type="button"
          className="btn-sm w-100"
          onClick={() => {
            confirm(
              'Exclusão',
              'Você tem certeza que deseja excluir? A operação só será efetivada depois que você salvar a opção.',
              () => {
                onDelete(buildLocation(), locationIndex);
              },
            );
          }}
        >
          <i className="fa fa-trash"></i> Excluir
        </Button>
      )}
    </>
  );
};

export default Location;
