import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import { formatDate } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { statuses as orderStatuses } from 'api/resources/order';

import './balance-due.scss';

const Order = () => {
  const [filters, setFilters] = useState({});

  const statusFilterOptions = () =>
    Object.keys(orderStatuses).map((status) => ({
      text: orderStatuses[status].name,
      value: status,
    }));

  const filterFields = [
    {
      attribute: 'number',
      type: 'text',
      label: 'Número do pedido',
      id: 'order-number-filter',
      klass: 'order-number-filter-container',
    },
    {
      attribute: 'status',
      type: 'select',
      label: 'Status',
      id: 'status-filter',
      klass: 'status-filter-container',
      options: [{ text: 'Todos', value: '' }].concat(statusFilterOptions()),
    },
  ];

  const sortingFields = {
    number: { direction: 'off' },
    status: { direction: 'off' },
    inserted_at: { direction: 'desc' },
  };

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Pedidos"
        columns={{
          number: {
            text: 'Número',
            render: (resource) => {
              if (!resource.number || resource.number === '') {
                return resource.number;
              }

              return (
                <a href={NamedRoutes.order_path(resource.number)}>
                  {resource.number}
                </a>
              );
            },
          },
          date: {
            text: 'Date',
            render: (resource) => resource.date.title,
          },
          price: {
            text: 'Preço',
            render: (resource) =>
              resource.billing.formatted.price_with_discount,
          },
          balance: {
            text: 'Balanço',
            render: (resource) => {
              let klass = '';

              if (resource.billing.balance_due > 0) {
                klass = 'to-receive-color';
              } else if (resource.billing.balance_due < 0) {
                klass = 'to-refund-color';
              }

              return (
                <span className={klass}>
                  {resource.billing.formatted.balance_due}
                </span>
              );
            },
          },
          status: {
            text: 'Status',
            render: (resource) => (
              <span className={`badge ${orderStatuses[resource.status].klass}`}>
                {orderStatuses[resource.status].name}
              </span>
            ),
          },
          inserted_at: {
            text: 'Criado em',
            render: (resource) => formatDate(resource.inserted_at),
          },
        }}
        resourceAPI={API.Order}
        resourceKey="number"
        hideId
        hideDeleteButton
        hideNewButton
        hideTitle
        filters={filters}
        sortingFields={sortingFields}
      />
    </>
  );
};

export default Order;
