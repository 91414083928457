import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useQuery } from 'react-query';

import API from 'api';

import AdjustmentsForm from './adjustments/AdjustmentsForm';
import AdjustmentsTable from './adjustments/AdjustmentsTable';

import './adjustments/styles.scss';

const AdjustmentsTab = ({ order, reload }) => {
  const [lastUpdate, setLastUpdate] = useState(Math.floor(Date.now() / 1000));

  const fetchUserStoreCreditsTotal = async () => {
    if (order.user) {
      const body = await API.UserStoreCredit.all(order.user.id);
      return body.data;
    }

    return {};
  };

  const { data: storeCreditsTotal } = useQuery(
    ['user-store-credits-total', order.user?.id],
    fetchUserStoreCreditsTotal,
  );

  const onApplyStoreCreditsHandler = () => {
    API.OrderStoreCredit.create(order.id, {}).then(() => {
      setLastUpdate(Math.floor(Date.now() / 1000));
      reload();
    });
  };

  const showStoreCredits =
    storeCreditsTotal &&
    storeCreditsTotal.total > 0 &&
    !order.has_store_credits_applied;

  return (
    <>
      <AdjustmentsTable order={order} lastUpdate={lastUpdate} />

      <br />
      <hr />
      <h4>Adicionar reembolso</h4>
      <br />

      <Row>
        <Col>
          <AdjustmentsForm
            order={order}
            setLastUpdate={setLastUpdate}
            reload={reload}
          />
        </Col>

        {showStoreCredits && (
          <Col className="text-center">
            <div className="store-credits-container simple-border">
              <span>
                Usuário possui
                <strong> {storeCreditsTotal.formatted_total} </strong>
                em créditos.
              </span>
              <br />
              <Button
                variant="primary"
                type="button"
                className="mt-32"
                onClick={onApplyStoreCreditsHandler}
              >
                Aplicar
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <hr />
      <Row>
        <Col>
          Serviço de pagamentos: <br />
          <a href="https://dashboard.stripe.com/" alt="Stripe" title="stripe">
            <i className="fa-brands fa-stripe"></i>
          </a>
        </Col>
      </Row>
    </>
  );
};

export default AdjustmentsTab;
