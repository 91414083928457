import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import { NamedRoutes } from 'config/routes/named-routes';

const Dates = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'title',
      type: 'text',
      label: 'Nome do date',
      id: 'date-title-filter',
      klass: '',
    },
  ];

  const sortingFields = {
    title: { direction: 'asc' },
    active: { direction: 'asc' },
  };

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Dates"
        columns={{
          title: {
            text: 'Nome',
            render: (resource) => {
              if (resource.active) {
                return (
                  <a
                    href={NamedRoutes.date_path(resource.id)}
                    alt={resource.title}
                  >
                    {resource.title}
                  </a>
                );
              }

              return (
                <a
                  className="inactive-date"
                  href={NamedRoutes.date_path(resource.id)}
                  alt={resource.title}
                >
                  {resource.title}
                </a>
              );
            },
          },
          active: {
            text: 'Ativo',
            render: (resource) => (resource.active ? 'Sim' : 'Não'),
          },
        }}
        resourceAPI={API.Date}
        filters={filters}
        hideTitle
        sortingFields={sortingFields}
      />
    </>
  );
};

export default Dates;
