import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import Modal from 'components/Modal/Modal';
import Plans from './change-option/Plans';

const OptionTab = ({ order, option, buildLabel, onClose }) => {
  const [showOptionChangeModal, setShowOptionChangeModal] = useState(false);

  const booleanText = (bool) => (bool ? 'sim' : 'não');
  const responsibles = option.responsibles.map((r) => r.name).join(', ');
  const timesOfDay = option.times_of_day.map((t) => t.name).join(', ');
  const formatLocation = (l) =>
    `* ${l.address} ${l.number || ''}
    ${l.complement || ''} ${l.reference || ''} ${l.city} ${l.state}
    ${l.country} - ${l.zip_code}`;

  const addresses = option.locations.map((location) => (
    <p key={location}>{formatLocation(location)}</p>
  ));

  return (
    <Container>
      <Row>
        <Col>
          {buildLabel('Opção escolhida', option.title)}
          {buildLabel('Label', option.label)}

          <Row>
            <Col>
              {order.billing.value_paid > 0 ? (
                <Alert variant="warning" className="w-75 p-1">
                  <Alert.Heading as="h5">
                    <i className="fa fa-exclamation-triangle mx-2"></i>
                    Atenção! Esse pedido <strong>já foi pago</strong>.
                  </Alert.Heading>
                  <p className="mb-1 mx-1">
                    Mudar a opção pode resultar em diferenças na cobrança.
                  </p>
                </Alert>
              ) : (
                ''
              )}

              <Button
                variant="dark"
                type="button"
                className="mt-4"
                onClick={() => {
                  setShowOptionChangeModal(true);
                }}
              >
                <i className="fa fa-edit"></i> Alterar opção
              </Button>
            </Col>
          </Row>
          <br />
          {buildLabel('Reponsáveis', responsibles)}
          {buildLabel('Períodos do dia', timesOfDay)}
          <br />
          <Form.Label>
            <strong>Endereços:</strong>
          </Form.Label>
          {addresses}
        </Col>
        <Col>
          {buildLabel('Date na natureza', booleanText(option.nature))}
          {buildLabel('Date inteligente', booleanText(option.brainy))}
          {buildLabel('Movimento', booleanText(option.movement))}
          {buildLabel('18+', booleanText(option.over18))}
          {buildLabel(
            'Depende do clima',
            booleanText(option.weather_dependent),
          )}
          {buildLabel('Precisa de carro', booleanText(option.car_required))}
          {buildLabel(
            'Bom para 1o encontro',
            booleanText(option.first_date_friendly),
          )}
        </Col>
      </Row>

      {showOptionChangeModal && (
        <Modal
          className="option-change-modal"
          title="Troca de opção"
          onClose={onClose}
        >
          <Plans
            order={order}
            onClose={() => {
              setShowOptionChangeModal(false);
              onClose();
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

export default OptionTab;
