/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

import { confirm } from 'lib/notifications';
import { isEmpty } from 'lib/string';

const Instruction = ({
  instruction,
  index,
  onInstructionChange,
  setHeaderErrors,
  onDelete,
}) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [indexesToRemove, setIndexesToRemove] = useState([]);

  const hasErrors = () => {
    const dataWithEmptyText = data.find((d) => isEmpty(d));
    const hasEmptyData =
      dataWithEmptyText !== null && dataWithEmptyText !== undefined;

    return isEmpty(title) || hasEmptyData || type === '';
  };

  useEffect(() => {
    setTitle(instruction.title || '');
    setType(instruction.type || '');
    setData(instruction.data || []);
  }, [index]);

  useEffect(() => {
    onInstructionChange(buildInstruction(), index);
    setHeaderErrors(hasErrors());
  }, [title, type, data]);

  const buildInstruction = () => ({
    title,
    type,
    data: data.filter((d) => d !== '--deleted--'),
  });

  const onDeleteData = (dataIndex) => {
    const newIndexesToRemove = [...indexesToRemove];
    newIndexesToRemove.push(dataIndex);
    setIndexesToRemove(newIndexesToRemove);
    onDataChange('--deleted--', dataIndex);
  };

  const onDataChange = (value, dataIndex) => {
    const newData = [...data];
    newData[dataIndex] = value;
    setData(newData);
  };

  return (
    <>
      <Input
        label="Título"
        value={title}
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        className={isEmpty(title) ? 'required' : ''}
      />

      <Select
        label="Tipo"
        className={type === '' ? 'required' : ''}
        options={[
          { text: 'Selecione', value: '' },
          { text: 'Bullets', value: 'bullet' },
          { text: 'Mapa', value: 'map' },
          { text: 'Parágrafo', value: 'paragraph' },
        ]}
        value={type}
        onChange={(e) => setType(e.target.value)}
      />

      {data.map((d, i) => (
        <div
          className={`instruction-line mb-3 ${
            indexesToRemove.includes(i) ? 'deleted' : ''
          }`}
          key={`#{d}-${i}`}
        >
          <Input
            label=""
            value={d}
            onChange={(e) => {
              onDataChange(e.target.value, i);
            }}
            className={isEmpty(d) ? 'required' : ''}
            containerClass="w-99"
          />

          {!hasErrors() && (
            <Button
              variant="danger"
              type="button"
              className="btn-sm"
              onClick={() => {
                confirm(
                  'Exclusão',
                  'Você tem certeza que deseja excluir? A operação só será efetivada depois que você salvar a opção.',
                  () => {
                    onDeleteData(i);
                  },
                );
              }}
            >
              <i className="fa fa-trash"></i>
            </Button>
          )}
        </div>
      ))}

      <Button
        variant="primary"
        type="button"
        className="btn-sm"
        onClick={() => {
          const newData = [...data];
          newData.push('');
          setData(newData);
        }}
      >
        <i className="fa fa-plus"></i> Adicionar texto
      </Button>

      <br />
      <br />

      {!hasErrors() && (
        <Button
          variant="danger"
          type="button"
          className="btn-sm"
          onClick={() => {
            confirm(
              'Exclusão',
              'Você tem certeza que deseja excluir? A operação só será efetivada depois que você salvar a opção.',
              () => {
                onDelete(buildInstruction(), index);
              },
            );
          }}
        >
          <i className="fa fa-trash"></i> Excluir instrução
        </Button>
      )}
    </>
  );
};

export default Instruction;
