import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import API from 'api';

import {
  formatDateTime,
  formatDate,
  formatDateTimeUsingSlashes,
} from 'lib/date-utils';

const bookingTab = ({ order, buildLabel }) => {
  const { date, option } = order;
  const types = date.date_types.map((dateType) => dateType.name).join(', ');

  const responsibles = option.responsibles.map((r) => r.name).join(', ');
  const formatLocation = (l) =>
    `* ${l.address} ${l.number || ''}
    ${l.complement || ''} ${l.reference || ''} ${l.city} ${l.state}
    ${l.country} - ${l.zip_code}`;

  const addresses = option.locations.map((location) => (
    <p key={location}>{formatLocation(location)}</p>
  ));

  const { billing } = order;

  return (
    <Container>
      <Row>
        <Col>
          <h4>Date</h4>
          <br />

          <div style={{ width: '100%' }}>
            <img className="thumbnail" src={date.image_url} alt={date.title} />
          </div>
          {buildLabel('Date', date.title)}
          {buildLabel('Descrição', date.description)}
          {buildLabel('Tipos do date', types)}
          {date.documentation && date.documentation.length > 0 && (
            <Button
              variant="dark"
              type="button"
              className="mt-4"
              onClick={() => {
                window.open(date.documentation, '_blank');
              }}
            >
              <i className="fa fa-book-reader"></i> Leia a documentação
            </Button>
          )}
        </Col>
        <Col>
          <h4>Opção</h4>
          <br />

          {buildLabel('Opção escolhida', option.title)}
          {buildLabel('Label', option.label)}
          <br />

          {buildLabel('Reponsáveis', responsibles)}
          <br />

          <Form.Label>
            <strong>Endereços:</strong>
          </Form.Label>
          {addresses}
        </Col>
        <Col>
          <h4>Pedido</h4>
          <br />

          {buildLabel('ID', order.id)}
          {buildLabel('Preço', billing.formatted.price)}
          {buildLabel('Desconto', billing.discount_percentage)}
          {buildLabel('Comentário', order.comment)}
          {buildLabel(
            'Pedido criado em',
            formatDateTimeUsingSlashes(order.inserted_at),
          )}

          <br />
          <br />
          {order.scheduled_for &&
            buildLabel('Agendado para', formatDateTime(order.scheduled_for))}
        </Col>

        {order.status !== 'pre_booking' && (
          <Col>
            <h4>Pré-agendamento</h4>
            <br />

            {buildLabel(
              'Dias preferidos para o date',
              API.Order.weekDaysString(order),
            )}
            {buildLabel(
              'Datas',
              `a partir de ${formatDate(order.start_date)}, das ${
                order.from_time
              } às ${order.to_time}`,
            )}

            <br />
            <br />

            {buildLabel('Nome do usuário', order.user_name)}
            {buildLabel('Email do usuário', order.user_email)}
            {buildLabel('CPF do usuário', order.user_cpf)}

            <br />
            <br />

            {buildLabel('Nome da companhia', order.significant_other_name)}
            {buildLabel('Email da companhia', order.significant_other_email)}
            {buildLabel('CPF da companhia', order.significant_other_cpf)}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default bookingTab;
