/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    backdrop: false,
    loading: false,
  },
  reducers: {
    showBackdrop(state) {
      state.backdrop = true;
    },

    hideBackdrop(state) {
      state.backdrop = false;
    },

    showLoading(state) {
      state.loading = true;
    },

    hideLoading(state) {
      state.loading = false;
    },
  },
});

export const pageActions = pageSlice.actions;

export default pageSlice;
