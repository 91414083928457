/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class Order extends Resource {
  findByNumber(number) {
    return this.client.get(`/order-by-number/${number}`);
  }

  updateTodoList(orderId, todoList) {
    return this.client.put(`/orders/${orderId}/update-todo-list`, {
      todo_list: todoList,
    });
  }

  weekDaysString(order) {
    const days = {
      monday: { text: 'Segunda', binary: '0100000' },
      tuesday: { text: 'Terça', binary: '0010000' },
      wednesday: { text: 'Quarta', binary: '0001000' },
      thursday: { text: 'Quinta', binary: '0000100' },
      friday: { text: 'Sexta', binary: '0000010' },
      saturday: { text: 'Sábado', binary: '0000001' },
      sunday: { text: 'Domingo', binary: '1000000' },
    };

    const includeDay = (weekDay) => {
      const weekDayData = days[weekDay];
      const intFromBinary = parseInt(weekDayData.binary, 2);
      return (order.week_days & intFromBinary) === intFromBinary
        ? weekDayData.text
        : null;
    };

    return [
      includeDay('sunday'),
      includeDay('monday'),
      includeDay('tuesday'),
      includeDay('wednesday'),
      includeDay('thursday'),
      includeDay('friday'),
      includeDay('saturday'),
    ]
      .filter((n) => n)
      .join(', ');
  }
}

export const statuses = {
  pre_booking: { name: 'Pré agendado', klass: 'bg-secondary' },
  placed: { name: 'Requisitado', klass: 'bg-dark' },
  waiting_for_changes: { name: 'Aguardando alteração', klass: 'bg-primary' },
  waiting_for_payment: { name: 'Aguardando pagamento', klass: 'bg-warning' },
  paid: { name: 'Pago', klass: 'bg-info text-dark' },
  ready: { name: 'Pronto', klass: 'bg-info text-dark' },
  completed: { name: 'Concluído', klass: 'bg-success' },
  didnt_happen: { name: 'Não rolou', klass: 'bg-danger' },
  canceled: { name: 'Cancelado', klass: 'bg-danger' },
};
