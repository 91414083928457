import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { keyPrefix } from './keyPrefix';

const Task = ({ task, attributes, disabled, onStatusChange }) => {
  const buildTask = () =>
    attributes.map((attr, attrIndex) => {
      const key = `${keyPrefix()}-task-item-${attrIndex}`;

      if (attr.render) {
        return attr.render(task, attrIndex);
      }

      if (attr.type === 'check') {
        return (
          <input
            key={key}
            disabled={disabled}
            className="task-check"
            type="checkbox"
            checked={!!task.done}
            onChange={onStatusChange}
          />
        );
      }

      if (attr.type === 'text') {
        return (
          <span className="task-text" key={key}>
            {task[attr.name]}
          </span>
        );
      }

      if (attr.type === 'number') {
        return (
          <span className="task-text" key={key}>
            {task[attr.name] || 0}
          </span>
        );
      }

      if (attr.type === 'textarea' && task[attr.name].length > 0) {
        return (
          <OverlayTrigger
            key={key}
            placement="top"
            overlay={<Tooltip>{task[attr.name]}</Tooltip>}
          >
            <div className="task-information" key={key}>
              <i className="fa-solid fa-info"></i>
            </div>
          </OverlayTrigger>
        );
      }

      return null;
    });

  return <>{buildTask()}</>;
};

export default Task;
