import API from 'api';

import ResponsibleForm from 'pages/dates/components/Responsibles/ResponsibleForm';
import Responsibles from 'pages/dates/components/Responsibles/Responsibles';

const ResponsiblesController = {
  basePath: '/dates/responsaveis',
  form: ResponsibleForm,
  page: Responsibles,
  resourceAPI: API.Responsible,
};

export default ResponsiblesController;
