import React from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import { NamedRoutes } from 'config/routes/named-routes';

const DateTypes = () => (
  <CrudList
    title="Tipo de date"
    columns={{
      name: {
        text: 'Nome',
        render: (resource) => (
          <a href={NamedRoutes.date_type_path(resource.id)} alt={resource.name}>
            {resource.name}
          </a>
        ),
      },
    }}
    resourceAPI={API.DateType}
  />
);

export default DateTypes;
