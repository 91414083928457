import React from 'react';

import LoadingIcon from './LoadingIcon';

import './styles.scss';

const Loading = () => (
  <div className="loading-container">
    <LoadingIcon />
  </div>
);

export default Loading;
