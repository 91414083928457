/* eslint-disable no-bitwise */
import ScopedResource from '../scoped-resource';

export default class StoreCreditLog extends ScopedResource {
  allByReference(reference) {
    return this.client.get(`/adjustment-references/${reference}/logs`, {});
  }
}

export const OPERATIONS = {
  credit: 'Crédito',
  debit: 'Débito',
  refund: 'Devolução',
  inactivation: 'Desativação',
  expiration: 'Expiração',
};
