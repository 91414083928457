import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { MONTHS } from 'lib/date-utils';

import './styles.scss';

const DateRangePicker = ({
  currentMonthKey,
  setCurrentMonthKey,
  setCurrentYear,
  currentYear,
}) => {
  const startYear = 2023;

  const years = [];

  for (let year = startYear; year <= new Date().getFullYear(); year += 1) {
    years.push(year);
  }

  const handleOnClick = (monthKey, year) => {
    setCurrentMonthKey(monthKey);
    setCurrentYear(year);
  };

  return (
    <div className="datePickerContainer">
      <DropdownButton
        className="dropdown-button-finances"
        id="dropdown-basic-button"
        title={MONTHS[currentMonthKey]}
        variant="light"
      >
        {Object.keys(MONTHS).map((key) => {
          const month = MONTHS[key];
          return (
            <Dropdown.Item
              as="button"
              key={key}
              onClick={() => handleOnClick(key, currentYear)}
            >
              {month}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <DropdownButton
        id="dropdown-basic-button"
        title={currentYear}
        variant="light"
      >
        {years.map((year) => (
          <Dropdown.Item
            as="button"
            key={year}
            onClick={() => handleOnClick(currentMonthKey, year)}
          >
            {year}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default DateRangePicker;
