/* eslint-disable no-alert */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';

import Button from 'components/TopBarButton/Button';
import { confirmFormStateBeforeRedirect } from 'lib/form';
import { getPathDomainFromUrl } from 'lib/url';
import { storeActions } from 'store';
import { PAGE_BUTTONS } from './pageButtons';

const ContextButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const showButtons = () => {
    const currentPath = getPathDomainFromUrl();
    const buttons = PAGE_BUTTONS[currentPath];

    if (buttons) {
      return buttons.map((button) => {
        const regex = new RegExp(`${button.path}/\\d+/editar`);
        const isOnPage =
          window.location.pathname === button.path ||
          window.location.pathname === `${button.path}/novo` ||
          regex.test(window.location.pathname);

        return (
          <Button
            key={button.name}
            iconClass={button.iconClass}
            name={button.name}
            active={isOnPage}
            onClick={() => {
              const { formState } = store.getState().form;

              confirmFormStateBeforeRedirect(formState, () => {
                dispatch(storeActions.form.setFormStateToUnchanged());
                navigate(button.path);
              });
            }}
          />
        );
      });
    }

    return null;
  };

  return <>{showButtons()}</>;
};

export default ContextButtons;
